
export default function track_custom(e) {
  //console.log("TRACK -> ", e); //return false;
  window.dataLayer||=[];
  window.dataLayer.push(e);
  return false;
}

export function track_image_opened(url){
  track_custom({"event": "custom_interaction",
                "interaction_type": 'open_image',
                "media_url": url});
}

export function track_gallery_interaction(media_url) {
  track_custom({"event": "article_interaction",
                "interaction_type": 'gallery',
                "media_url": media_url});
  
}

export function track_search_usage(term, count) {
  if (term) {
    track_custom( {"event": "search",
                   "search_term": term,
                   "search_category": "",
                   "count_of_search_results": count});
  } 
}

export function track_video_startstop(etype, murl, mlen, mcur) {  
  // etype = ['start','stop']

  let fieldname = "media_"+etype+"_length";
  let payload = {
      "event": "multimedia_interaction",
      "interaction_type": "open_multimedia",
      "media_url": murl,
      "media_type": "video",
      "media_length": mlen
    };
  payload[ fieldname ] = mcur ;
  track_custom(payload);
}

export function track_article_interaction(kind, option) {
  track_custom({"event": "article_interaction",
                "interaction_type": kind,
                "interaction_option": option});
}

export function track_banner_clicked(campaign, banner) {
  track_custom({"event": "novychas_banner_click",
                "banner_campaign": campaign,
                "banner_creative": banner});
}

export function track_banner_displayed(campaign, banner) {
  track_custom({"event": "novychas_banner_view",
                "campaign": campaign,
                "banner": banner});
}
